import request from "@/utils/request";

export function addRole(data){
    return request({
        url:'/api/role/add',
        method:'post',
        data
        }
    )
}

export function getRoleList(){
    return request({
        url:'/admin/base/userList',
        method:'get'
        }
    )
}

export function selByName(query){
    return request({
        url:'/api/role/find_by_name',
        method:'get',
        params: query
        }
    )
}

export function getRoleByIdForPermission(query){
    return request({
        url:'/api/role/find_permission_by_role_id',
        method:'get',
        params: query
        }
    )
}

export function delRole(query){
    return request({
        url:'/api/role/delete',
        method:'get',
        params: query
        }
    )
}

export function batchesDeletesRole(query){
    return request({
        url:'/api/role/batches_deletes',
        method:'get',
        params: query
        }
    )
}

export function getRoleByUserId(query){
    return request({
        url:'/admin/base/rUserList',
        method:'get',
        params: query
        }
    )
}

export function updateRole(data){
    return request({
        url:'/api/role/update',
        method:'post',
        data
        }
    )
}

export function userDetails(data){
    return request({
        url:'/admin/base/userDetails',
        method:'get',
        params: {
            user_id:data
        }
        }
    )
}

export function getUserChapter(data) {
    return request({
        url:'/admin/base/userChapter',
        method:'get',
        params: data
        }
    )
}
export function userChapterAuthSave(data) {
    return request({
        url:'/admin/base/userChapterAuth',
        method:'post',
        data
        }
    )
}

// 用户详情已激活课程列表
export function userOrder(data) {
    return request({
        url:'/admin/base/userOrder',
        method:'get',
        params:data
        }
    )
}

// 用户学习记录
export function userStudyRecord(data) {
    return request({
        url:'/admin/base/userStudyRecord',
        method:'get',
        params:data
        }
    )
}
