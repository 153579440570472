import request from "@/utils/request";
import md5 from 'js-md5'
export function getRole(data) {
  return request({
    url: "/admin/base/rUserList",
    method: "get",
    params: data
  });
}
export function editRoles(data) {
  return request({
    url: "/admin/base/rUserEdit",
    method: "put",
    data
  });
}
export function removeRoles(data) {
  return request({
    url: "/admin/base/rUserDel",
    method: "delete",
    data: {
      id: data
    }
  });
}
export function addRoles(data) {
  let addRoleData = {
    desc:data.desc,
    password:md5(data.password),
    phone:data.phone,
    role:data.role,
    username:data.username,
  }
  return request({
    url: "/admin/base/rUserAdd",
    method: "post",
    data:addRoleData
  });
}
export function editPasswords(data) {
 let editPassDats= { 
   id: data.id,
   new_password:md5(data.new_password),
   old_password:md5(data.old_password),
   rep_password:md5(data.rep_password),
  }
  return request({
    url: "/admin/base/rUserEditPwd",
    method: "put",
    data:editPassDats
  });
}
export function editEnables(data) {
  return request({
    url: "/admin/base/rUserEnable",
    method: "put",
    data
  });
}
// 获取手机号码区号列表
export function AreaCode(params) { //测试接口 记得改!
	return request({
		url: '/japanese/v3/areaCode',
		method: 'get',
		params
	})
}
// 添加用户
export function addUserRoles(data) {
  return request({
    url: "/admin/base/rUserAdd",
    method: "post",
    data
  });
}
