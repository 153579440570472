import request from "@/utils/request";
// import { getToken } from "@/utils/auth";

export function getOrder(query) {
    return request({
        url: "/admin/base/orderList",
        method: "get",
        params: query
    });
}
export function removeOrder(data) {
    return request({
        url: "/admin/base/orderDel",
        method: "delete",
        data: {
            id: data
        }
    });
}
//查看协议
export function getContract(data) {
    return request({
        url: "/admin/base/getContract",
        method: "get",
        params: data
    });
}
export function OrderEnable(data) {
    return request({
        url: "/admin/base/orderEnable",
        method: "put",
        data
    });
}


// 获取订单章节树状数据
export function OrderChapter(params) {
    return request({
        url: "/admin/base/orderChapter",
        method: "get",
        params
    });
}

// 订单章节保存
export function OrderChapterSave(data) {
    return request({
        url: "/admin/base/orderChapterSave",
        method: "post",
        data
    });
}

// 订单章节模式切换
export function OrderSwitchMode(data) {
    return request({
        url: "/admin/base/orderSwitchMode",
        method: "put",
        data
    });
}

// 设置章节解锁时间
export function OrderSetUnlock(data) {
    return request({
        url: "/admin/base/orderSetUnlock",
        method: "put",
        data
    });
}

// 导出订单
export function ExportOrder(params) {
    return request({
        url: "/admin/base/exportOrder",
        method: "get",
        params,
        responseType: "blob"
    });
}

// 修改订单
export function OrderEdit(data) {
    return request({
        url: "/admin/base/orderEdit",
        method: "put",
        data
    });
}

// 生成订单
export function OrderAdd(data) {
    return request({
        url: "/admin/base/orderAdd",
        method: "post",
        data
    });
}

// 选择用户
export function SelectUser(params) {
    return request({
        url: "/admin/base/selectUser",
        method: "get",
        params
    });
}

// 根据用户ID获取订单列表
export function UserIdOrder(params) {
    return request({
        url: "/admin/base/userIdOrder",
        method: "get",
        params
    });
}

// 订单详情
export function OrderDetails(params) {
    return request({
        url: "/admin/base/orderDetails",
        method: "get",
        params
    });
}

// 获取订单学习进度
export function OrderRateLearn(params) {
    return request({
        url: "/admin/base/orderRateLearn",
        method: "get",
        params
    });
}


// 订单撤销作业
export function OrderUndoTask(data) {
    return request({
        url: "/admin/base/orderUndoTask",
        method: "put",
        data
    });
}

//护照签约
export function foreignersOrder(data) {
    return request({
        url: "/admin/base/signContract",
        method: "post",
        data
    });
}