export function timestampToTime(time) {
    var date = new Date(time)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = date.getDate() + ' '
    var h = date.getHours() + ':'
    var m = date.getMinutes() + ':'
    var s = date.getSeconds()
    return Y + M + D + h + m + s
}
export function timeToTimestamp(timestamp) {
	var date = new Date(timestamp)
	var timestamp = date.getTime();
}
