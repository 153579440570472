<template>
  <div>
    <el-card>
      <div class="handle-box">
        <el-form ref="form" :inline="true">
        <el-form-item label="用户名">
        <el-input
          v-model.trim="queryParams.nickname"
          placeholder="用户名"
        ></el-input>
        </el-form-item>
          <el-form-item label="手机">
            <el-input
              placeholder="请输入手机号"
              v-model.trim="queryParams.phone"
            ></el-input>
          </el-form-item>
          <el-form-item label="时间">
              <el-date-picker
                v-model="times"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchList"
              >查询</el-button>
          </el-form-item>
          <el-form-item class="generate">
            <el-button type="primary" @click="addOne"
              >添加用户</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="userTable.tableData"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="phone"
          label="手机"
          align="center"
        ></el-table-column>
        <el-table-column label="用户名" prop="nickname"></el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <el-image
              class="table-td-thumb"
              :src="scope.row.avatar"
              :preview-src-list="[scope.row.avatar]"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.is_enable == 1"
              @click="editEnable(scope.row.id, 0)"
              >启用</el-tag
            >
            <el-tag type="danger" v-else @click="editEnable(scope.row.id, 1)"
              >禁用</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="login_ip"
          label="登录IP"
          align="center"
        ></el-table-column>

        <el-table-column label="最后登录时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.login_time !== null">
              {{
                $moment(scope.row.login_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.create_time !== null">
              {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="toHandleEdit(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
              size="small"
            ></el-button>
             <el-button type="success" icon="el-icon-info" circle size="small"
             @click="Details(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

     <!-- 添加管理员弹框 -->
      <el-dialog title="添加用户" :visible.sync="addDialog" width="30%" @close="closed">
        <el-form :model="addForm" ref="addForm">
          <el-form-item label="区号" label-width="100px" prop="area_code">
            <el-select v-model="addForm.area_code" placeholder="请选择">
              <el-option
                v-for="item in areaCodeList"
                :key="item.area"
                :label="item.area +'(' + item.code + ')'"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号" label-width="100px" prop="phone">
            <el-input v-model="addForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" label-width="100px" prop="password">
            <el-input v-model="addForm.password" type="password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label-width="40%">
            <el-button type="primary" @click="addRole">立即添加</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

     <!-- 章节设置 -->
        <el-dialog
          width="40%"
          top="3%"
          title="订单章节设置"
          :visible.sync="orderSectionDialog"
          append-to-body
          :close-on-click-modal=false
          @close="setClosed">
          <div class="jurisdiction_box">
            <div class="unlock_mode">
              <div class="unlock_mode_title">解锁模式选择</div>
              <div>
                <el-radio-group v-model="modeRadio" size="small" @change="radioChang">
                    <el-radio :label="item.key" border v-for="item in unlockMode" :key="item.key">{{item.value}}</el-radio>
                 </el-radio-group>
              </div>
            </div>

            <div class="unlock_mode" v-if="modeRadio!==1">
              <el-form ref="form" :inline="true">

                <el-form-item label="章节开始">
                  <el-date-picker
                    v-model="startTime"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>

                <el-form-item label="章节结束">
                  <el-date-picker
                    v-model="endTime"
                    type="datetime"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="saveChapterTime">保存</el-button>
                </el-form-item>
              </el-form>
             
            </div>
            <div class="jurisdiction-content">
              <div class="title_mode">自定义模式</div>

              <el-tree
                :data="userChapterList"
                show-checkbox
                node-key="chapter_id"
                :props="defaultProps"
                :default-checked-keys="chapter_ids"
                :default-expand-all=true
                ref="tree">
                   <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span class="node_label">{{ node.label }}</span>
                    <span class="unlock_time">
                      {{
                        data.unlock_time?'解锁时间：'+$moment(data.unlock_time * 1000).format("YYYY-MM-DD HH:mm:ss"):''
                      }}
                    </span>
                  </span>
              </el-tree>
            </div>
            <div class="jurisdiction-confirm">
               <el-button type="primary" @click="confirmPermissions">确定</el-button>
               <el-button type="primary" @click="orderSectionDialog=false">取消</el-button>
            </div>
          </div>
        </el-dialog>


      <!-- 编辑弹出框 -->
      <el-dialog
        title="编辑"
        :visible.sync="editDialogVisiable"
        width="30%"
      >
        <el-form ref="form" label-width="70px">
          <el-row class="editUserItem">
            <el-col :span="4" class="editUserItemLeft">
              <span>昵称：</span>
            </el-col>
            <el-col :span="12">
              <el-input
                size="small"
                v-model="editUserForm.nickname"
                clearable
              ></el-input>
            </el-col>
          </el-row>
          <el-row class="editUserItem">
            <el-col :span="4" class="editUserItemLeft">
              <span>手机号：</span>
            </el-col>
            <el-col :span="12">
              <el-input
                size="small"
                v-model="editUserForm.phone"
                clearable
              ></el-input>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisiable = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="handleEditUser">确 定</el-button>
        </span>
      </el-dialog>


      <!-- 用户详情弹框 -->
       <el-dialog
        title="用户详情"
        :visible.sync="detailsDialogVisiable"
        width="80%"
        top="3%"
        :close-on-click-modal=false
      >
        <div class="detail-box">
          <div class="detail">
            <div class="detail-img">
              <img :src="userDetails.avatar" alt="">
            </div>
            <div class="detail-one">
              <p>用户名：{{userDetails.nickname}}</p>
              <p>QQ：{{userDetails.qq}}</p>
              <p>最后登录IP：{{userDetails.login_ip}}</p>
            </div>
            <div class="detail-two">
              <p>手机：{{userDetails.area_code}} {{userDetails.phone}}</p>
              <p>生日：{{$moment(userDetails.birthday*1000).format('YYYY-MM-DD')}}</p>
              <p>最后登录时间：{{$moment(userDetails.login_time*1000).format('YYYY-MM-DD HH:mm:ss')}}</p>
            </div>
            <div class="detail-three">
              <p>微信：{{userDetails.wechat}}</p>
              <p>地址：{{userDetails.address}}</p>
            </div>
          </div>
          <div class="tabs">
              <el-button type="primary" size="medium" :class="{selected: tabs==0}" @click="Tabs(0)">已激活课程</el-button>
              <el-button type="primary" size="medium"  :class="{selected: tabs==1}" @click="Tabs(1)">学习记录</el-button>
          </div>
          <div class="detail-content" style="overflow: auto;">
              <el-table
              :data="detailTableData"
              style="width: 100%"
              max-height="504"
              v-if="tabs==0"
              border
              >
                <el-table-column
                  prop="thumb.avthumb"
                  label="缩略图"
                  align="center" 
                >
                 <template slot-scope="scope">
                  <img :src="scope.row.thumb.avthumb" alt="" style="width: 160px;height:90px">
                </template>
                </el-table-column>
                 <el-table-column
                  prop="goods_name"
                  label="课程名"
                  align="center" 
                >
                </el-table-column>
                 <el-table-column
                  prop="order_sn"
                  label="订单编号"
                  align="center" 
                >
                </el-table-column>
                  <el-table-column
                  prop="order_status"
                  label="订单状态"
                  align="center" 
                >
                </el-table-column>
                <el-table-column prop="unlock_mode" label="解锁模式" align="center">
                  <template slot-scope="scope" >
                      <div style="color:red;">
                        {{scope.row.unlock_mode}}
                      </div>
                  </template>
                </el-table-column>
                 <el-table-column
                  prop="expire"
                  label="到期时间"
                  align="center" 
                >
                <template slot-scope="scope">
                 <div v-if="scope.row.login_time !== null">
                  {{
                    $moment(scope.row.expire * 1000).format(
                      "YYYY-MM-DD"
                    )
                  }}
                  </div>
                  <div v-else></div>
                </template>
                </el-table-column>
                 <el-table-column
                  prop="date"
                  label="操作"
                  align="center" 
                >
                    <template slot-scope="scope">
                       <el-button type="info" size="small" icon="el-icon-setting" @click="getOrderChapter(scope.row.order_id)" circle></el-button>
                    </template>
                </el-table-column>
              </el-table>

              <el-table
              :data="userStudyRecordList"
              style="width: 100%"
              max-height="504"
              v-else
              >
                <el-table-column
                  prop="goods.thumbFile.thumb"
                  label="缩略图"
                  align="center" 
                >
                 <template slot-scope="scope">
                  <img :src="scope.row.goods.thumbFile.thumb" alt="" style="width: 160px;height:90px; cursor: pointer;" @click="ImgBox(scope.row.thumb.avthumb)">
                </template>
                </el-table-column>
                 <el-table-column
                  prop="goods.goods_name"
                  label="课程名"
                  align="center" 
                >
                </el-table-column>
                 <el-table-column
                  prop="goodsLib.lib_name"
                  label="章节"
                  align="center" 
                >
                </el-table-column>
                <el-table-column
                  prop="goodsLibChapter.chapter_name"
                  label="小节"
                  align="center" 
                >
                </el-table-column>
                
                <el-table-column
                  prop="progress"
                  label="学习进度"
                  align="center" 
                >
                <template slot-scope="scope">
                  <el-progress :percentage="scope.row.progress"></el-progress>
                </template>
                </el-table-column>

                <el-table-column
                  prop="expire"
                  label="学习时间"
                  align="center" 
                >
                <template slot-scope="scope">
                 <div v-if="scope.row.study_time !== null">
                  {{
                    $moment(scope.row.study_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}
                  </div>
                  <div v-else></div>
                </template>
                </el-table-column>
              </el-table>
          </div>
        </div>

      </el-dialog>

        <el-dialog
        title=""
        :visible.sync="isImg"
        width="60%"
        top="3%"
      >
        <img :src="imgsSrc" alt="" style="width: 100%;">
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import store from "@/store";
import {
  OrderChapter,
  OrderChapterSave,
  OrderSwitchMode,
  OrderSetUnlock,
} from "@/api/order";
import {
  addUser,
  getUserList,
  delUser,
  selByName,
  editUser,
  editEnables,
} from "@/api/userApi";
import {
  getRoleByUserId,
  getRoleList,
  userDetails,
  getUserChapter,
  userChapterAuthSave,
  userOrder,
  userStudyRecord,
} from "@/api/roleApi";
import {
  addUserRoles,
  AreaCode
} from "@/api/rolelist.js";
import { timestampToTime } from "@/utils/timeUtils";
import { warningDialog } from "@/utils/dialog";
import { log } from "util";

export default {
  name: "userlist",
  data() {
    return {
      userTable: {
        pageIndex: 1,
        pageTotal: 0,
        tableData: [],
        pageSize: 10,
        multipleSelection: [],
      },
      times: null,
      nicknameEdOrAdd: "",
      phoneEdOrAdd: "",
      accountEdOrAdd: "",
      usernoteEdOrAdd: "",
      addUserData: {
        password: "",
        addDialogVisible: false,
      },
      editUserForm: {
        id: "",
        nickname: "",
        phone: "",
      },
      editDialogVisiable: false,
      queryParams: {
        nickname: null,
        phone: null,
        page: 1,
        limit: 10,
        code: "",
        goods_id: null,
        start_time: null,
        end_time: null,
      },
      times: null,
      total: 0,
      detailsDialogVisiable: false,
      userDetails: {},
      detailTableData: [],
      isJurisdiction: false,
      userChapterForm: {
        user_id: "",
        goods_id: "",
      },
      userChapterList: [],
      defaultProps: {
        children: "chapter",
        label: "lib_name",
      },
      chapter_ids: [],
      jurisdictionForm: {
        user_id: "",
        goods_id: "",
        chapterArr: [],
      },
      tabs: 0,
      userStudyRecordList: [],
      isImg: false,
      imgsSrc: "",
      // jurisdictionTitle:''

      orderSectionDialog: false,
      userChapterList: [],
      chapter_ids: [],
      unlockMode: [],
      modeRadio: null,
      choiceRadio: null,
      unlock_mode: null,
      defaultProps: {
        children: "chapter",
        label: "lib_name",
      },
      order_id: null,
      userId: null,
      startTime: "",
      endTime: "",
      addDialog: false,
      addForm: {
        area_code: "",
        phone: "",
        password: "",
        user_role: 2,
      },
      areaCodeList: [],
    };
  },
  methods: {
    addOne(){
      AreaCode().then(res => {
        if (res.code !== 1) {
						this.$message.error(res.data.msg)
						return
					}
				this.areaCodeList = res.data;
        this.addDialog = true;
      });
    },
     // 添加用户
    addRole() {
      if(this.addForm.area_code == ''){
        this.$message.error("区号不能为空")
        return
      }else if(this.addForm.phone == ''){
        this.$message.error("手机号不能为空")
        return
      }else if(this.addForm.password == ''){
        this.$message.error("密码不能为空")
        return
      }
      addUserRoles(this.addForm).then(res => {
        if (res.code !== 1){
            this.$message.error("添加用户失败")
            this.addDialog = false;
            return
        }
        this.$message.success("添加用户成功");
        this.addDialog = false;
        this.getUserData();
      });
    },
    closed(){
      this.addForm = {
        area_code: "",
        phone: "",
        password: "",
        role: "2"
      }
    },
    // 设置章节解锁时间
    saveChapterTime() {
      let data = {
        order_id: this.order_id,
        chapter_start: this.startTime,
        chapter_end: this.endTime,
      };
      if (data.chapter_start !== null && data.chapter_start !== "") {
        data.chapter_start = new Date(data.chapter_start).getTime() / 1000;
      } else {
        data.chapter_start = 0;
      }
      if (data.chapter_end !== null && data.chapter_end !== "") {
        data.chapter_end = new Date(data.chapter_end).getTime() / 1000;
      } else {
        data.chapter_end = 0;
      }

      OrderSetUnlock(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("设置章节解锁时间成功");
      });
    },
    // 解锁模式切换
    async radioChang(e) {
      this.unlock_mode = e;
      if (this.modeRadio !== this.choiceRadio) {
        this.modeRadio = this.choiceRadio;
      }
      const result = await this.$confirm(
        "是否要修改解锁模式?",
        "修改提示",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (result !== "confirm") return this.$message.info("已经取消修改");
      let data = {
        order_id: this.order_id,
        unlock_mode: this.unlock_mode,
      };
      OrderSwitchMode(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改解锁模式成功");
        this.modeRadio = this.unlock_mode;
      });
    },

    // 获取章节权限列表
    getOrderChapter(id) {
      this.order_id = id;
      this.orderSectionDialog = true;
      OrderChapter({ order_id: id }).then((res) => {
        this.userChapterList = res.data.lib;
        this.chapter_ids = res.data.chapter_ids;
        this.unlockMode = res.data.ModeArr;
        this.modeRadio = res.data.unlock_mode.key;
        this.choiceRadio = this.modeRadio;
        if (res.data.chapter_start == 0) {
          this.startTime = "";
        } else {
          this.startTime = res.data.chapter_start * 1000;
        }
        if (res.data.chapter_end == 0) {
          this.endTime = "";
        } else {
          this.endTime = res.data.chapter_end * 1000;
        }
      });
    },

    // 权限确认
    confirmPermissions() {
      let keys = this.$refs.tree.getCheckedKeys();
      function delArrVal(arr, val) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == val) {
            arr.splice(i, 1);
            i--;
          }
        }
        return arr;
      }
      let key = delArrVal(keys, undefined);
      let data = {
        order_id: this.order_id,
        chapterArr: key,
      };
      OrderChapterSave(data).then((res) => {
        if (res.code == 1) {
          // this.isJurisdiction=false
          this.$message.success("操作成功!");
        }
      });
    },

    // 章节设置弹窗关闭事件
    setClosed() {
      this.Details(this.userId);
    },

    Tabs(index) {
      this.tabs = index;
    },
    //获取用户列表
    getUserData() {
      getUserList(this.queryParams).then((res) => {
        this.total = res.data.total;
        this.userTable.tableData = res.data.data;
        this.userTable.pageTotal = res.data.total;
      });
    },
    //删除用户
    handleDelUser(index) {
      var data = {
        id: this.userTable.tableData[index].id,
      };
      delUser(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除用户成功!");
        this.userTable.pageIndex = 1;
        this.getUserData();
      });
    },
    // 删除操作
    handleDelete(index, row) {
      warningDialog("确定要删除吗？").then(() => {
        //删除操作
        this.handleDelUser(index);
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.userTable.multipleSelection = val;
    },
    toTime(timeStr) {
      return timestampToTime(parseInt(timeStr));
    },
    delAllSelection() {
      warningDialog("确定要删除吗？").then(() => {
        var select = "";
        for (let i = 0; i < this.userTable.multipleSelection.length; i++) {
          if (i == 0) {
            select += this.userTable.multipleSelection[i].id;
          } else {
            select += "," + this.userTable.multipleSelection[i].id;
          }
        }
        this.delSelectUser(select);
      });
    },
    //多选删除
    delSelectUser(_ids) {
      var data = {
        ids: _ids,
      };
      delMoreUser(data).then((res) => {
        this.getUserData();
      });
    },
    // 导航搜索
    searchList() {
      this.queryParams.page = 1;
      if (this.times == null) {
        this.queryParams.start_time = "";
        this.queryParams.end_time = "";
      }
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getUserData();
    },
    //日期搜索
    onSubmit() {
      if (this.times) {
        this.select.start_time = new Date(this.times[0]).getTime() / 1000;
        this.select.end_time = new Date(this.times[1]).getTime() / 1000;
      }
    },

    // 去编辑(点击编辑按钮)
    toHandleEdit(item) {
      this.editDialogVisiable = true;
      this.editUserForm.id = item.id;
      this.editUserForm.nickname = item.nickname;
      this.editUserForm.phone = item.phone;
    },
    // 保存编辑
    handleEditUser() {
      editUser(this.editUserForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑成功!");
        this.editDialogVisiable = false;
        this.getUserData();
      });
    },
    // 获取身份列表
    getUserRole(_userId) {
      var data = {
        id: _userId,
      };
      getRoleByUserId(data).then((res) => {
        this.roleEdOrAddId = res.data.id;
      });
    },
    getAddUserRoles() {
      getRoleList().then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].isDefault == 1) {
            this.roleEdOrAddId = res.data[i].id;
          }
        }
        if (this.roleEdOrAddId == -1) {
          this.roleEdOrAddId = res.data.id;
        }
        this.roleList = res.data;
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.userTable, "pageIndex", val);
      if (this.select.name == "") {
        this.getUserData();
      } else {
        this.selbyname();
      }
    },
    // 用户禁用和启用状态
    editEnable(id, target) {
      if (target === 0) {
        // console.log("禁用");
        warningDialog("确定要禁用吗？").then(() => {
          editEnables({
            id: id,
            enable: target,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.warning("禁用成功");
              this.getUserData();
            }
          });
        });
      } else {
        warningDialog("确定要启用吗？").then(() => {
          editEnables({
            id: id,
            enable: target,
          }).then((res) => {
            this.$message.success("启用成功");
            this.getUserData();
          });
        });
      }
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getUserData();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getUserData();
    },
    Details(id) {
      this.detailsDialogVisiable = true;
      this.userId = id;
      userDetails(id).then((res) => {
        this.userDetails = res.data;
      });
      userOrder({ user_id: id }).then((res) => {
        this.detailTableData = res.data;
      });
      userStudyRecord({ user_id: id }).then((res) => {
        this.userStudyRecordList = res.data;
      });
    },
    ImgBox(src) {
      this.isImg = true;
      this.imgsSrc = src;
    },
  },
  mounted() {
    this.getUserData();
    this.getAddUserRoles();
  },
};
</script>

<style scoped lang="less">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.add {
  position: fixed;
  /*align-self: flex-end;*/
  right: 130px;
}

.refresh {
  /* position: fixed; */
  /*align-self: flex-end;*/
  /* right: 80px; */
  float: right;
}

.edit {
  margin-left: 50px;
  width: 100px;
  margin-top: 30px;
}

.editUserItemLeft {
  padding-left: 15px;
  margin-top: 5px;
}

.editUserItem {
  margin-bottom: 10px;
  padding-left: 15px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.detail-box {
  height: 750px;
}
.detail {
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 30px;
}
.detail .detail-img {
  flex: 2;
}
.detail-img img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.detail .detail-one {
  flex: 4;
}
.detail .detail-two {
  flex: 5;
}
.detail .detail-three {
  flex: 3;
}
.detail p {
  line-height: 28px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}
.detail-box .tabs {
  border-bottom: 2px solid #38a28a;
}
.detail-box .tabs .el-button {
  width: 160px;
  height: 50px;
  border-radius: 0;
  border: none;
  background-color: #fff;
  color: #323232;
}
.detail-box .tabs .selected {
  background-color: #38a28a;
  color: #fff;
}
.detail-box .detail-content {
  height: 504px;
  background-color: #f0f0f0;
}
.jurisdiction {
  height: 700px;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-content {
  height: 600px;
  overflow: auto;
}
.jurisdiction .jurisdiction-confirm {
  text-align: center;
  height: 100px;
  line-height: 100px;
  margin: auto;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-confirm .el-button {
  width: 90px;
  height: 40px;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

.jurisdiction_box {
  /* height: 700px; */
  border-top: 1px solid #ccc;

  .unlock_mode {
    margin-top: 30px;
    height: 100px;
    .unlock_mode_title {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 20px;
    }
  }
  .jurisdiction-content {
    height: 600px;
    overflow: auto;
    .title_mode {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 20px;
    }
    ::v-deep .custom-tree-node {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .node_label {
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .unlock_time {
        // margin-left: 20px;
        // float: right;
        color: #37a58c;
      }
    }
  }
  .jurisdiction-confirm {
    text-align: center;
    height: 100px;
    line-height: 100px;
    margin: auto;
    border-top: 1px solid #ccc;
    .el-button {
      width: 90px;
      height: 40px;
    }
  }
}
.generate {
  float: right;
}
</style>
