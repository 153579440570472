import request from "@/utils/request";

export function addUser(data) {
  return request({
    url: "/h5/userRegister",
    method: "post",
    data
  });
}

export function getUserList(query) {
  return request({
    url: "/admin/base/userList",
    method: "get",
    params: query
  });
}

export function delUser(query) {
  return request({
    url: "/admin/base/userDel",
    method: "delete",
    params: query
  });
}



// export function selByName(query) {
//   return request({
//     url: "/admin/base/userList",
//     method: "get",
//     params: query
//   });
// }

export function editUser(data) {
  return request({
    url: "/admin/base/userEdit",
    method: "put",
    data
  });
}

export function getInfo() {
  return request({
    url: "/admin/base/rUserInfo",
    method: "get"
  });
}


export function editEnables(data) {
  return request({
    url: "/admin/base/userEnable",
    method: "put",
    data
  });
}

// 获取意向用户列表
export function sourceUserList(data) {
  return request({
    url: "/admin/base/getSourceUserList",
    method: "get",
    params: data
  });
}

// 修改意向用户列表
export function editSourceUserList(data) {
  return request({
    url: "/admin/base/updateUserSource",
    method: "post",
    data
  });
}